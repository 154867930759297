.products {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (--max-md) {
        align-items: center;
    }

    @media (--min-md) {
        flex-flow: wrap row;
    }
}

.product {
    width: 100%;
    max-width: calc(100% - calc(2 * var(--gap)));
    margin: var(--gap) 0;
    padding: calc(2 * var(--gap)) var(--gap);
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-start;
    background: linear-gradient(45deg, hsl(var(--color-black)), hsl(var(--color-black-lighten-1)));
    color: hsl(var(--color-white));
    text-align: center;
    border-radius: var(--global-border-radius);

    a {
        color: hsl(var(--color-white));
        text-decoration: none;
    }

    .section {
        width: 100%;
        position: relative;
    }

    @media (--min-sm) {
        width: 75vw;
    }

    @media (--min-md) {
        max-width: calc(50% - calc(2 * var(--gap)));
        margin: var(--gap);
    }
}

.mainProduct {
    max-width: 100%;
    flex-flow: row wrap;

    .section {

        @media (--min-md) {
            max-width: calc(50% - calc(2 * var(--gap)));
            margin: var(--gap);
        }
    }

    h2 {
        text-transform: lowercase;

        &::first-letter {
            text-transform: uppercase;
        }

        @media (--min-sm) {
            padding-right: 3rem;
            padding-left: 3rem;
        }

        @media (--min-md) {
            padding-right: 4rem;
            padding-left: 4rem;
        }
    }

    .purifier {
        padding: 0;
    }

    @media (--min-md) {
        width: 100%;
    }
}

.purifierLink {
    display: flex;
}

.heading {
    margin-bottom: var(--gap);
    font-size: 3rem;
    text-transform: uppercase;
}

.subheading {
    margin-bottom: var(--gap);
    font-family: 'Helvetica Bold', Tahoma, Nimbus Sans L, Free Sans, sans-serif;
    font-size: 1.4rem;

    @media (--min-sm) {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    @media (--min-md) {
        padding-right: 4rem;
        padding-left: 4rem;
    }
}

.description {
    min-height: 8rem;
    margin-bottom: var(--gap);
    color: hsl(var(--color-gray-lighten-5));
    font-size: 1em;
    line-height: 1.5;

    @media (--min-sm) {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    @media (--min-md) {
        padding-right: 4rem;
        padding-left: 4rem;
    }
}

.figure {
    margin-top: auto;
    padding: calc(5 * var(--gap)) 0;

    @media (--min-sm) {
        padding: calc(5 * var(--gap)) 4em;
    }
}

.purifier {
    text-align: right;

    @media (--min-sm) {
        text-align: center;
    }
}

.link {
    display: block;
    text-decoration: none;
}

.icon {
    position: absolute;
    top: 50%;
    left: 3rem;
    transform: translateY(-50%);

    @media (--min-xs) {
        left: 0;
    }

    @media (--min-sm) {
        left: 1rem;
    }

    @media (--min-md) {
        left: 1rem;
    }
}
