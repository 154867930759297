.specification {
    margin-bottom: var(--gap);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9rem;

    @media (--min-sm) {
        margin-bottom: 0;
        flex-flow: row wrap;
    }
}

.detail {
    margin-bottom: var(--gap);
}

.key {
    width: 100%;
    margin-bottom: calc(var(--gap) / 2);
    color: hsl(var(--color-gray-lighten-5));
    font-size: 0.8rem;
    text-align: center;
    text-transform: uppercase;

    @media (--min-sm) {
        width: 35%;
        margin-bottom: 0;
        padding-right: var(--gap);
        text-align: left;

        .detail & {
            width: 50%;
        }
    }
}

.value {
    width: 100%;
    text-align: center;

    .anchor {
        --anchor-border-color: var(--color-blue-lighten-3);
        color: hsl(var(--color-blue-lighten-3));
    }

    @media (--min-sm) {
        width: 65%;
        padding-left: var(--gap);
        text-align: left;

        .detail & {
            width: 50%;
        }
    }
}

.list {
    line-height: 0;
}

.item {
    margin: calc(var(--gap) / 4);
    display: inline;
    line-height: 1.25;
}

.color {
    --size: 14px;
    width: var(--size);
    height: var(--size);
    display: inline-block;
    position: relative;
    border-radius: 50%;

    &:focus,
    &:hover {

        .tooltip {
            opacity: 1;
        }
    }

    @media (--min-sm) {
        --size: 16px;
    }

    @media (--min-md) {
        --size: 18px;
    }
}

.tooltip {
    width: auto;
    min-width: 150px;
    max-width: 400px;
    padding: calc(0.5 * var(--gap)) var(--gap);
    position: absolute;
    bottom: 200%;
    left: 50%;
    opacity: 0;
    background: hsla(var(--color-white), 0.9);
    color: hsl(var(--color-black));
    transform: translateX(-50%);
    transition-property: opacity;
    transition-duration: var(--transition-in);
    transition-timing-function: var(--transition-timing);
    border-radius: var(--global-border-radius);

    &::after {
        width: 16px;
        height: 16px;
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        content: '';
        transform: translateX(-50%);
        border-width: 8px 8px 0;
        border-style: solid;
        border-color: hsla(var(--color-white), 0.9) transparent transparent;
    }
}
