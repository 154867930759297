.picture {
    display: block;
    position: relative;
    line-height: 0;
}

.image {
    max-width: 100%;
    height: auto;
    content-visibility: auto;
}

.hasLoader {
    position: relative;
    z-index: 1;
}

.loader {
    --max-size: 60px;
    margin-top: calc(var(--max-size) / -2);
    margin-left: calc(var(--max-size) / -2);
    top: 50%;
    left: 50%;
    z-index: 0;
}
